export const blockedEmailDomains: Array<string> = [
  "tui.se",
  "tuifly.be",
  "tui.com",
  "tuifly.nl",
  "tuifly.cw",
  "tui.nl",
  "tui.co.uk",
  "tuifly.com",
  "tuigroup.onmicrosoft.com",
  "tui.be",
  "corendon.nl",
  "w2fly.es",
  "w2fly.pt",
  "cmacgm-aircargo.com"
];
